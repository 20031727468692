import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CoolAppBar from "../components/AppBar";
import FullScreenBox from "../components/FullScreenBox";
import { Box, Link } from "@mui/material";

const TermsAndConditions = () => {
  return (
    <FullScreenBox>
      <CoolAppBar />
      <Container component="main" maxWidth="lg">
        <Box textAlign="center">
          <Typography variant="h5">Terms & Conditions</Typography>
        </Box>
        <Typography variant="body1" sx={{ mt: 2 }}>
          View the current{" "}
          <Link
            href="https://app.termly.io/document/terms-of-service/6b0344c4-0bd2-472a-854e-9cc1571ce346"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms
          </Link>{" "}
          here.
        </Typography>
      </Container>
    </FullScreenBox>
  );
};

export default TermsAndConditions;
