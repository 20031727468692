import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const Footer = () => {
  function Copyright() {
    return (
      <Typography variant="body2" color="text.secondary">
        {"Copyright © Pluggo (Pty) Ltd. "}
        {new Date().getFullYear()}
      </Typography>
    );
  }

  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
      }}
    >
      <Container
        maxWidth="sm"
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box
          component="nav"
          sx={{
            "& a:not(:first-child)::before": {
              content: '"•"',
              margin: "0 10px",
            },
            "& a": {
              textDecoration: "none",
            },
            "& a span": {
              textDecoration: "underline",
            },
          }}
        >
          <Link
            href="https://app.termly.io/document/privacy-policy/52366e47-0496-4871-a8f7-cf6c0472b827#contact"
            color="primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Privacy</span>
          </Link>
          <Link
            href="https://app.termly.io/document/terms-of-service/6b0344c4-0bd2-472a-854e-9cc1571ce346"
            color="primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Terms</span>
          </Link>
          <Link href="mailto:hello@pluggo.app">
            <span>Contact Us</span>
          </Link>
        </Box>
        <Copyright />
      </Container>
    </Box>
  );
};

export default Footer;
