import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CoolAppBar from "../components/AppBar";
import FullScreenBox from "../components/FullScreenBox";
import { Box, Link } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <FullScreenBox>
      <CoolAppBar />
      <Container component="main" maxWidth="lg">
        <Box textAlign="center">
          <Typography variant="h5">Privacy Policy</Typography>
        </Box>
        <Typography variant="body1" sx={{ mt: 2 }}>
          View the current{" "}
          <Link
            href="https://app.termly.io/document/privacy-policy/52366e47-0496-4871-a8f7-cf6c0472b827"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>{" "}
          here.
        </Typography>
      </Container>
    </FullScreenBox>
  );
};

export default PrivacyPolicy;
